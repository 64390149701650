import './App.css';
import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import ReactGA from 'react-ga';

const Profile = React.lazy(() => import("./pages/Profile"))
const Footer = React.lazy(() => import("./components/Footer"))
const Header = React.lazy(() => import("./components/Header"))
const Festival = React.lazy(() => import("./pages/festival/Festival"))
const ParadeInfo = React.lazy(() => import("./pages/festival/ParadeInfo"))
const ThingsToDo = React.lazy(() => import("./pages/festival/ThingsToDo"))
const GuidesAndReviews = React.lazy(() => import("./pages/festival/GuidesAndReviews"))
const TravelersShouldKnow = React.lazy(() => import("./pages/festival/TravelersShouldKnow"))
const History = React.lazy(() => import("./pages/festival/History"))
const News = React.lazy(() => import("./pages/festival/News"))
const BandDates = React.lazy(() => import("./pages/bandDate/BandDates"))
const Results = React.lazy(() => import("./pages/festival/Results"))
const Blogs = React.lazy(() => import("./pages/Blog/Blogs"))
const Blog = React.lazy(() => import("./pages/Blog/Blog"))
const BlogForm = React.lazy(() => import("./pages/Blog/BlogForm"))
const Awards = React.lazy(() => import("./pages/Awards"))
const FAQPage = React.lazy(() => import("./pages/FAQ"))
// const Festivals = React.lazy(() => import("./pages/festival/Festivals"))
const Carnival = React.lazy(() => import("./pages/festival/Carnival"))
const BandDate = React.lazy(() => import("./pages/bandDate/BandDate"))
const EventPage = React.lazy(() => import("./pages/event/EventPage"))
const Designers = React.lazy(() => import("./pages/designers/Designers"))
const Designer = React.lazy(() => import("./pages/designers/Designer"))
const TradePage = React.lazy(() => import("./pages/Trade/TradePage"))
const Events = React.lazy(() => import("./pages/event/Events"))
const EventForm = React.lazy(() => import("./pages/event/EventForm"))
const TradeForm = React.lazy(() => import('./pages/Trade/TradeForm'))
const AdForm = React.lazy(() => import('./pages/AdForm'))
const Landing = React.lazy(() => import('./pages/Landing'))
const CostumeBandCompare = React.lazy(() => import('./pages/bandDate/CostumeBandCompare'))
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'))
const Statics = React.lazy(() => import('./pages/Statics'))
const Bands=React.lazy(()=>import("./pages/band/Bands"))
const Band=React.lazy(()=>import("./pages/band/Band"))
const ChaserAbout=React.lazy(()=>import("./pages/chasers/About"))
const LeaderboardUser=React.lazy(()=>import("./pages/chasers/LeaderboardUser"))
const FestivalList=React.lazy(()=>import("./pages/festival/List"))
const LoginByOtp = React.lazy(()=>import ("../src/components/LoginByOtp"))
const User = React.lazy(()=>import ("../src/pages/user"))
const Reviews = React.lazy(() => import ("../src/pages/reviews/Reviews"));

function manageRouteFestival(params){
  var path=params.location.pathname
  var yearRegx=/^[0-9]{4}$/
  var year=path.split('/').pop()
  if(year && yearRegx.test(year)) return(<FestivalList year={year} />)
  return (<Festival festivalId={year}/>)
}
function App() {
  useEffect(()=> {
    if(process.env.REACT_APP_ENV === "production") {
      ReactGA.initialize('UA-190561080-1');
    }
  }, [])

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
    if(process.env.REACT_APP_ENV === "production") {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location.pathname, location.search]);

  return (

    <React.Suspense fallback={<div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}><FadeLoader size={150}/></div>}>
      <div id="app" className="App">
        <Header />
        <div className="wrapper">
          <div id="main">
              <Switch>
                <Route path="/" exact component={Landing} />
                <Route path="/profile" exact component={Profile} />
                {/* <Route path="/festivals" exact component={Festivals} /> */}
                <Route path="/festivals" exact component={FestivalList} />
                <Route path="/festival/:slug" exact component={Carnival} />
                <Route path="/blogs" exact component={Blogs} />
                <Route path="/blogs/:slug" exact component={Blog} />
                <Route path="/blog/form" exact component={BlogForm} />
                <Route path="/awards" exact component={Awards} />
                <Route path="/awards/:slug" exact component={Results} />
                <Route path='/festivals/:festivalid' render={manageRouteFestival} />
                <Route path="/festivals/:festivalid/about" exact component={Festival} />
                <Route path="/reviews" exact component={Reviews} />
                <Route path="/festivals/:festivalid/paradeinfo" exact component={ParadeInfo} />
                <Route path="/bands" exact component={Bands} />
                <Route path="/band/:slug" exact component={Band} />
                <Route path="/band-dates" exact component={BandDates} />
                {/* <Route path="/band/form" exact component={BandForm} /> */}
                <Route path="/bands/compare" exact component={CostumeBandCompare} />
                <Route path="/bands/compare/:firstSlug" exact component={CostumeBandCompare} />
                <Route path="/bands/compare/:firstSlug/:secondSlug" exact component={CostumeBandCompare} />
                <Route path="/bands/compare/:firstSlug/:secondSlug/:third" exact component={CostumeBandCompare} />
                <Route path="/bands/compare/:firstSlug/:secondSlug/:third/:fourth" exact component={CostumeBandCompare} />
                <Route path="/chaser/" exact component={ChaserAbout} />
                <Route path="/chaser/:year" exact component={ChaserAbout} />
                <Route path="/chaser/:userID/:year" exact component={LeaderboardUser} />
                <Route path="/events/:slug" exact component={EventPage} />
                <Route path="/events" exact component={Events} />
                <Route path="/event/form" exact component={EventForm} />
                <Route path="/designers" exact component={Designers} />
                <Route path="/designers/:designerid" exct component={Designer} />
                <Route path="/festivals/:festivalid/thingstodo" exact component={ThingsToDo} />
                <Route path="/festivals/:festivalid/guidesandreviews" exact component={GuidesAndReviews} />
                <Route path="/news" exact component={News} />
                <Route path="/festivals/:festivalid/travelersshouldknow" exact component={TravelersShouldKnow} />
                <Route path="/festivals/:festivalid/history" exact component={History} />
                <Route path="/bands/:slug" exact component={BandDate} />
                <Route path="/faq" exact component={FAQPage} />
                <Route path="/trade" exact component={TradePage} />
                <Route path="/trade/form" exact component={TradeForm} />
                <Route path="/ad/form" exact component={AdForm} />
                <Route path="/404" exact component={NotFoundPage} status={404}/>
                <Route path="/:slug" exact component={Statics} />
                <Route path="/login-otp/:otp" exact component={LoginByOtp} />
                <Route path="/user/:id" exact component={User} />

              </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </React.Suspense>
  );
}


export default App;
